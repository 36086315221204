import {LineItemModel} from './LineItem.model';
import {AppliedCouponModel} from './AppliedCoupon.model';
import {ItemTypePreset} from './ItemType.model';
import {PriceSummaryModel} from './PriceSummary.model';
import {GiftCardModel} from './GiftCard.model';
import {AdditionalFeeModel} from './AdditionalFee.model';
import {CustomFieldModel} from './CustomField.model';
import {BuyerInfoModel} from './BuyerInfo.model';
import {AddressWithContactModel} from './AddressWithContact.model';
import {ShippingOptionModel} from './ShippingOption.model';
import {ErrorsModel} from './Errors.model';
import {PriceModel} from './Price.model';
import {ExtendedFieldsFragment, ShippingOptionFragment} from '../../../gql/graphql';
import {ViolationModel} from './Violation.model';
import {Checkout} from '../../../types/checkoutApp.types';
import {SelectedShippingOptionModel} from './SelectedShippingOption.model';
import {CustomSettingsModel} from './CustomSettings.model';
import {AggregatedTaxBreakdownModel} from './AggregatedTaxBreakdown.model';
import {PaymentPolicyModel} from '../common/PaymentPolicy.model';
import {mapNullToUndefined} from '../../utils/mapperHelper.utils';

export class CheckoutModel {
  public id: string;
  public cartId?: string;
  public hasDigitalItems: boolean;
  public buyerNote?: string;
  public buyerLanguage?: string;
  public channelType?: string;
  public siteLanguage?: string;
  public lineItems: LineItemModel[];
  public numberOfItems: number;
  public appliedCoupon?: AppliedCouponModel;
  public hasShippableItems: boolean;
  public isCardTokenizationCheckout: boolean;
  public paymentPolicies: PaymentPolicyModel[];
  public hasItemsWithLongPrice: boolean;
  public hasSubscriptionItems: boolean;
  public itemTypes: Set<ItemTypePreset>;
  public priceSummary: PriceSummaryModel;
  public aggregatedTaxBreakdowns: AggregatedTaxBreakdownModel[];
  public payNowTotalAfterGiftCard: PriceModel;
  public totalAfterGiftCard?: PriceModel;
  public giftCard?: GiftCardModel;
  public payLater?: PriceSummaryModel;
  public payAfterFreeTrial?: PriceSummaryModel;
  public additionalFees: AdditionalFeeModel[];
  public customField?: CustomFieldModel;
  public buyerInfo: BuyerInfoModel;
  public billingInfo?: AddressWithContactModel;
  public shippingDestination?: AddressWithContactModel;
  public selectedShippingOption?: SelectedShippingOptionModel;
  public shippingOptions: ShippingOptionModel[];
  public pickupOptions: ShippingOptionModel[];
  public currency?: string;
  public errors: ErrorsModel;
  public violations: ViolationModel[];
  public extendedFields?: ExtendedFieldsFragment;
  public customSettings?: CustomSettingsModel;
  public hasOutOfStockLineItems: boolean = false;
  public hasPartialShippingOptions: boolean = false;
  public hasMembersOnlyItems: boolean = false;
  public purchaseFlowId?: string;
  public lineItemPolicies?: Record<string, string[]>;
  public paymentRequired: boolean;
  public taxIncludedInPrice?: boolean;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  constructor(
    checkout: Checkout,
    {
      timezone,
      shouldAddPoliciesToLineItems,
      shouldNotSortShippingOptions,
      shouldExtractShippingAddFieldsToCheckoutModel,
      fixCouponError,
    }: {
      timezone?: string;
      shouldAddPoliciesToLineItems?: boolean;
      shouldNotSortShippingOptions?: boolean;
      shouldExtractShippingAddFieldsToCheckoutModel?: boolean;
      fixCouponError?: boolean;
    } = {}
  ) {
    this.id = checkout.id!;
    this.paymentRequired = checkout.paymentRequired ?? /* istanbul ignore next */ true;
    this.taxIncludedInPrice = shouldExtractShippingAddFieldsToCheckoutModel
      ? checkout.taxIncludedInPrice ?? /* istanbul ignore next */ false
      : /* istanbul ignore next */ undefined;
    this.purchaseFlowId = mapNullToUndefined(checkout.purchaseFlowId);
    this.cartId = mapNullToUndefined(checkout.cartId);
    this.buyerLanguage = mapNullToUndefined(checkout.buyerLanguage);
    this.channelType = mapNullToUndefined(checkout.channelType);
    this.siteLanguage = mapNullToUndefined(checkout.siteLanguage);
    this.buyerNote = mapNullToUndefined(checkout.buyerNote);
    this.lineItems = (checkout.lineItems ?? []).map(
      (item) =>
        new LineItemModel(
          item!,
          checkout.membershipOptions!,
          checkout.externalEnrichedLineItems?.enrichedLineItems,
          shouldAddPoliciesToLineItems,
          shouldExtractShippingAddFieldsToCheckoutModel
        )
    );
    this.lineItemPolicies = shouldAddPoliciesToLineItems ? groupItemPolicies(this.lineItems) : undefined;
    this.hasDigitalItems = this.lineItems.some((lineItem) => lineItem.itemType.preset === ItemTypePreset.DIGITAL);
    this.numberOfItems = this.lineItems.reduce((acc: number, {quantity}: LineItemModel) => acc + quantity, 0);
    this.appliedCoupon = AppliedCouponModel.fromDTO(mapNullToUndefined(checkout.appliedDiscounts));
    this.hasShippableItems = this.lineItems.some((lineItem) => lineItem.shippable);
    this.paymentPolicies = this.lineItems
      .filter((lineItem) => lineItem.consentRequiredPaymentPolicy)
      .map((lineItem) => new PaymentPolicyModel(lineItem));
    this.isCardTokenizationCheckout = this.lineItems.some((lineItem) => lineItem.savePaymentMethod);
    this.hasOutOfStockLineItems = this.lineItems.some(({quantity}) => quantity === 0);
    this.hasMembersOnlyItems = this.lineItems.some((lineItem) => lineItem.membersOnly);
    this.hasItemsWithLongPrice = this.lineItems.some((lineItem) => lineItem.prices.isLongPrice);
    this.hasSubscriptionItems = this.lineItems.some((lineItem) => lineItem.subscription);
    this.itemTypes = new Set(
      this.lineItems.filter(({itemType}) => itemType.preset).map(({itemType}) => itemType.preset!)
    );
    this.priceSummary = new PriceSummaryModel(mapNullToUndefined(checkout.priceSummary));
    this.aggregatedTaxBreakdowns =
      checkout.taxSummary?.aggregatedTaxBreakdown?.map(
        (aggregatedTaxBreakdown) => new AggregatedTaxBreakdownModel(aggregatedTaxBreakdown)
      ) ?? [];
    this.payNowTotalAfterGiftCard = new PriceModel(mapNullToUndefined(checkout.payNowTotalAfterGiftCard));
    this.totalAfterGiftCard = new PriceModel(mapNullToUndefined(checkout.totalAfterGiftCard));
    this.giftCard = checkout.giftCard ? new GiftCardModel(checkout.giftCard) : undefined;
    this.payLater = checkout.payLater ? new PriceSummaryModel(checkout.payLater) : undefined;
    this.payAfterFreeTrial = checkout.payAfterFreeTrial ? new PriceSummaryModel(checkout.payAfterFreeTrial) : undefined;
    this.additionalFees = (checkout.additionalFees ?? [])
      .filter((fee) => !!fee)
      .map((additionalFee) => new AdditionalFeeModel(additionalFee!));
    this.customField = checkout.customFields?.[0] ? new CustomFieldModel(checkout.customFields?.[0]) : undefined;
    this.buyerInfo = new BuyerInfoModel(mapNullToUndefined(checkout.buyerInfo));
    this.billingInfo = checkout.billingInfo ? new AddressWithContactModel(checkout.billingInfo) : undefined;
    this.shippingDestination = checkout.shippingInfo?.shippingDestination
      ? new AddressWithContactModel(checkout.shippingInfo?.shippingDestination)
      : undefined;
    this.selectedShippingOption = checkout.shippingInfo?.selectedCarrierServiceOption
      ? new SelectedShippingOptionModel(checkout.shippingInfo?.selectedCarrierServiceOption, {
          timezone,
        })
      : undefined;

    const allOptions = getFlattenedShippingOptions(checkout);
    this.hasPartialShippingOptions = allOptions.some((option) => Boolean(option.partial));
    this.shippingOptions = getOptionModels(
      allOptions.filter((option) => !isPickupOption(option)),
      this.selectedShippingOption?.code,
      {timezone, shouldNotSortShippingOptions}
    );
    this.pickupOptions = getOptionModels(allOptions.filter(isPickupOption), this.selectedShippingOption?.code, {
      timezone,
      shouldNotSortShippingOptions,
    });
    this.currency = mapNullToUndefined(checkout.currency);
    this.errors = new ErrorsModel(this.lineItems, mapNullToUndefined(checkout.calculationErrors), fixCouponError);
    this.violations = mapViolations(checkout);
    this.extendedFields = mapNullToUndefined(checkout.extendedFields);
    this.customSettings = new CustomSettingsModel(checkout.customSettings);
  }
}

function groupItemPolicies(lineItems: LineItemModel[]): Record<string, string[]> {
  return lineItems.reduce((acc: Record<string, string[]>, lineItem) => {
    lineItem.policies?.forEach((policy) => {
      if (!acc[policy.title]) {
        acc[policy.title] = [];
      }
      /* istanbul ignore next */
      if (policy.content) {
        acc[policy.title].push(policy.content);
      }
    });
    return acc;
  }, {});
}

function mapViolations(checkout: Checkout): ViolationModel[] {
  return (checkout.violations ?? /* istanbul ignore next */ [])
    .filter((violation) => Boolean(violation))
    .map((violation) => new ViolationModel(violation!));
}

function isPickupOption({logistics}: ShippingOptionFragment): boolean {
  return !!logistics?.pickupDetails;
}

function getFlattenedShippingOptions(checkout: Checkout): (ShippingOptionFragment & {carrierId: string})[] {
  return (
    checkout.shippingInfo?.carrierServiceOptions?.flatMap((carrierServiceOption) =>
      carrierServiceOption?.shippingOptions
        ? (carrierServiceOption.shippingOptions.map((option) => ({
            ...option,
            carrierId: carrierServiceOption.carrierId!,
          })) as (ShippingOptionFragment & {carrierId: string})[])
        : /* istanbul ignore next */ []
    ) ?? []
  );
}

function getOptionModels(
  options: (ShippingOptionFragment & {carrierId: string})[],
  selectedShippingOptionId: string | undefined,
  {
    timezone,
    shouldNotSortShippingOptions,
  }: {
    timezone?: string;
    shouldNotSortShippingOptions?: boolean;
  }
): ShippingOptionModel[] {
  const optionsByCode = options.reduce<{[code: string]: ShippingOptionFragment[]}>((acc, option) => {
    const key = toOptionKey(option);
    return {...acc, [key]: [...(acc[key] ?? []), option]};
  }, {});

  return shouldNotSortShippingOptions
    ? Object.keys(optionsByCode).map((code) => {
        const [option] = optionsByCode[code];
        if (optionsByCode[code].length === 1) {
          return new ShippingOptionModel(option, undefined, selectedShippingOptionId, {
            timezone,
          });
        }
        return new ShippingOptionModel(option, optionsByCode[code], selectedShippingOptionId, {
          timezone,
        });
      })
    : sortedShippingOptions(
        // eslint-disable-next-line sonarjs/no-identical-functions
        Object.keys(optionsByCode).map((code) => {
          const [option] = optionsByCode[code];
          if (optionsByCode[code].length === 1) {
            return new ShippingOptionModel(option, undefined, selectedShippingOptionId, {
              timezone,
            });
          }
          return new ShippingOptionModel(option, optionsByCode[code], selectedShippingOptionId, {
            timezone,
          });
        })
      );
}

const toOptionKey = (option: ShippingOptionFragment & {carrierId: string}) => {
  if (!option.logistics?.deliveryTime) {
    return `${option.carrierId}-${option.code}`;
  }
  return `${option.carrierId}-${option.title!}`;
};

function sortedShippingOptions(options: ShippingOptionModel[]): ShippingOptionModel[] {
  return options.sort((a, b) => {
    return a.initialPrice.amount - b.initialPrice.amount;
  });
}
